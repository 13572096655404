export const name = 'Requirements'
export const order = 1;

# Requirements

Compatible with the most widely used CMS (e.g. Wordpress, Typo3)

The user's browser must have Javascript (ES2017+) enabled and support the HTML5 `postMessage` feature. If Javascript is explicitly disabled in the browser, the application cannot be loaded.

Embedded CarmarketFrames must have a viewport that is at least 450px wide. We recommend a minimal >=1024px on desktop.

### Supported Browsers

- Microsoft Edge: actual major-version and 1 predecessor
- Mozilla Firefox: actual major-version and 2 predecessors
- Google Chrome: actual major-version and 2 predecessors
- Apple Safari: actual major-version and 1 predecessor
- Samsung Internet: actual major-version and 1 predecessor

### Known restrictions

- CarmarketFrame is not compatible with websites created with the Hoststar Designer. This website builder does not provide the assets in the defined order to the browser.
