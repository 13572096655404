import { Link, useSearchParams } from "react-router-dom";
import Logo from "../modules/layout/components/Logo";
import usePathBuilder from "../router/hooks/usePathBuilder";
import * as docs from "./documents";

export default () => {
  const { homePath } = usePathBuilder();

  const [urlSearchParams] = useSearchParams();

  return (
    <div className="md-sidebar">
      <Logo context="header" link={homePath({ absolute: true })} />
      <ul>
        {Object.entries(docs)
          .sort(([, a], [, b]) => (a.order || 0) - (b.order || 0))
          .map(([docName, module]) => {
            return (
              <li key={docName}>
                <Link to={`/${docName}?${urlSearchParams.toString()}`}>{module.name || docName}</Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
