export const name = 'Configuration'
export const order = 3;

# Configuration Settings

In this section the different possibilities for configuration of your CarmarketFrame are shown.

## Initialization

### selector [string]

The element into which the CarmarketFrame is rendered can be specified using a selector. For more information about DOM elements and selectors see:

* [Locating DOM elements](https://developer.mozilla.org/en-US/docs/Web/API/Document_object_model/Locating_DOM_elements_using_selectors)
* [Selectors](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Selectors)

### type [string]

Set the type of the whitelabel embedding.

* Full whitelabel integration (filter, listings, vehicle detail pages): `whitelabel` [default if no type is set]
* Single row of vehicles: `vehicle-row`

### id [string]

Set the CarmarketFrame ID to the attribute `id`. The ID is provided by Carmarket. If you don't have a CarmarketFrame ID please contact Carmarket.

### language [string: de, fr, it]

Use the applicable language for your CarmarketFrame.

### dealerIds [array of int] for type=whitelabel

Each of your locations has a dealer ID. This ID is provided by Carmarket. It is possible to add several locations to your Carmarketframe by adding the ID to the specific configuration line.

`dealerIds: [600, 589],`

Add all applicable dealerIds for this specific CarmarketFrame.

It's possible to separate your locations to different CarmarketFrames if different CarmarketFrameIDs are available.

### dealerId [int] for type=vehicle-row

Each of your locations has a dealer ID. This ID is provided by Carmarket. Add the required ID for a vehicle row.

`dealerId: 600,`

### brandIds [array of int] (optional)

It is possible to reduce the displayed brands to some specific brands.

`brandIds: [1],`  (optional)

Add all applicable brandIds for this specific CarmarketFrame. An empty array does not reduce the brands. Ask Carkmarket for ids of specific brands.

### searchPlaceholder [string] (optional, for type whitelabel only)

In the search bar input field you can find the standard placeholder "z.B. BMW X3 schwarz". To replace the text according to your needs replace the `searchPlaceholder`.

`searchPlaceholder: 'z.B. Toyota Yaris',`

### searchTermString [string] (optional, for type vehicle-row only)

An optional search term string to filter vehicles in a vehicle row. Use the search page of carmarket.ch to create a suitable term.

`searchTermString: 'otherFilters=4&fuelType=g%7C1',`

## Filters (optional)

The Carmarket Platform provides a lot of filters to find the best fitting car. It's possible to configure the shown filters on the sidebar on the left side and exclude filters in general. All available filters your can find in the table below.

### sidebarFilters

The sidebar filters on the left can have an impact how fast your customers can find a fitting result. So it's possible to configure the filters according your needs. This is optional.

Add the Key from the required sidebar Filters to:

`sidebarFilters: ["Key 1", "Key 2", "Key n"],  `  (optional, standard configuration=popular filters)

Possible filters to be added: see table below. You can find there also the information about popular filters.

### excludedFilters

If not all filters are needed it's possible to exclude filters. Excluded filters will be completely removed and will not be available on the all filters page.

Add the Key for the to be excluded filters to:

`excludedFilters: ["Key 1", "Key n"], `  (optional)

The list of all available filters you can find in the table below.

### List of available filters


| Key                 | Popular | Description                                                  | Sample Values                                      |
| ------------------- | :-----: | ------------------------------------------------------------ | -------------------------------------------------- |
| `brandModel`        |    ✓    | Combined filter for the brand and model information          | brand: Ford, BMW, Volvo<br />model: BMW X5, BMW i3 |
| `firstRegistration` |    ✓    | Date from the first registration on the vehicle registration document. | 01.01.2020-31.12.2022                              |
| `fuelType`          |    ✓    | Multilevel and multiselectable filter for the fuel the vehicle is using | Electro, Diesel, Hybrid                            |
| `mileage`           |    ✓    | amount of kilometers the vehicle has driven                  | 30'000 - 50'000 km                                 |
| `price`             |    ✓    | Price or range of price                                      | 80'000-120'000 CHF                                 |
| `transmission`      |    ✓    | Type of transmission                                         | Schaltgetriebe, Halbautomatik, Automat Stufenlos   |
| `vehicleCondition`  |    ✓    | Condition of the vehicle                                     | New, Used                                          |
| `batteryCapacity`   |         | Capacity of the battery of an electrical vehicle             | >30 kWh                                            |
| `batteryRange`      |         | Range of the battery of an electrical vehicle                | > 100 WLTP                                         |
| `bodyType`          |         | Type of the body from the vehicle                            | Cabriolet, Coupé, Van                              |
| `ccm`               |         | Volume of the Internal Combustion Engine Cylinders in cm<sup>3</sup> | 900 - 1'200 cm<sup>3</sup>                         |
| `cylinders`         |         | Amount of cylinders                                          | 4-6                                                |
| `driveTypes`        |         | Drive of the vehicle                                         | 4WD, AWD, Vorderradantrieb                         |
| `doors`             |         | Amount of doors on the vehicle                               | 3-5                                                |
| `emissionClass`     |         | Emission Class the vehicle is in                             | A+, D, E                                           |
| `emptyWeight`       |         | Weight of the empty car ready to drive.                      | 1'900 kg                                           |
| `exteriorColor`     |         | Outside / Exterior Color of the vehicle                      | blue, red                                          |
| `geo`               |         | Location and range around where the vehicle is placed        | 8000 Zürich, 20 km                                 |
| `interiorColor`     |         | Inside / Interior Color of the vehicle                       | white, black, grey                                 |
| `options`           |         | Options                                                      | 360° Camera, ABS, Airbag                           |
| `otherFilters`      |         | Other vehicle attributes                                     | Ab MFK, unfallfrei, Heimlieferung möglich          |
| `power`             |         | The power of the vehicle differentiated between PS and kW.   | Power:  70-100 kW                                  |
| `seats`             |         | Amount of seats on the vehicle                               | 5-7                                                |
| `trailerWeightMax`  |         | Maximum weight of the trailer                                | < 2'000 kg                                          |
| `upholstery`        |         | Materials used for interior upholstery (e.g. seats)          | Leather, Velour, Fabric                            |
| `vehicleClasses`    |         | Class of the vehicle                                         | Executive, Small family, Large family              |
| `vehicleTypes`      |         | Type of the vehicle                                          | Nutzfahrzeug, Personenwagen, Wohnmobil             |


## Theme (optional)

It's possible to add your individual styling with a Colortheme to the CarmarketFrame

The colour is added in the [hex color](https://developer.mozilla.org/en-US/docs/Web/CSS/hex-color) code format. You can define the following styles.

| Key                   | Description                                      |
| --------------------- | ------------------------------------------------ |
| `themePageBackground` | Background of the CarmarketFrame page            |
| `themePageForeground` | Foreground of the CarmarketFrame page            |
| `themePrimary`        | Colour of primary buttons                        |
| `themePrimaryHover`   | Colour of primary buttons on hover               |
| `themeOnPrimary`      | Colour of primary buttons chosen                 |
| `themeSecondary`      | Colour of secondary buttons                      |
| `themeSecondaryHover` | Colour of secondary buttons on hover             |
| `themeOnSecondary`    | Colour of secondary buttons chosen               |
| `themeBoxBackground`  | Background colour of the Vehicle Boxes           |
| `themeBorder`         | Border colour of the Vehicle Boxes               |
| `themeMeta`           | Font colour of vehicle data and attribute titles |
