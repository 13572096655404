import { useParams } from "react-router-dom";
import { renderToString } from "react-dom/server";
import { ReactElement } from "react";
import filterXSS from "xss";
import { Params } from "../router/constants/Params";
import Sidebar from "./Sidebar";
import * as docs from "./documents";

type MdxModule = {
  name?: string;
  order?: number;
  default: (props?: any) => unknown;
};

export default () => {
  const search = new URLSearchParams(window.location.search);

  const params = useParams();
  const pageId = params[Params.pageId];
  let populated = "";

  if (pageId) {
    const page = (docs as { [key: string]: MdxModule })[pageId];
    populated = page ? renderToString(page.default() as ReactElement) : "";

    search.forEach((param, key) => {
      populated = populated.replaceAll(`###${key}###`, filterXSS(param));
    });
  }

  return (
    <div className="md-container">
      <Sidebar />
      <div
        dangerouslySetInnerHTML={{
          __html: populated,
        }}
        className="md-content markdown-body"
      />
    </div>
  );
};
