export const name = 'Getting Started'
export const order = 0;

# Getting Started

## Application Description

The CarmarketFrame (**Carmarket whitelabel solution for iframe embeds**) lets you embed the search and detail pages of your offered vehicles. The embedded application can be color styled according to your needs. Moreover, there are many settings to configure the search for vehicles according to the offering. The advanced functionalities of the embedded application allow direct links to embedded vehicles. In addition, all lead processes can be started directly.

## Minimal Example

```html
<div id="carmarket-frame"></div>
<script>
  window.carmarketFrameConfiguration = {
      selector: '#carmarket-frame', // Selector of the element to replace
      id: '###frameId###', // CarmarketFrame ID provided by Carmarket
      language: 'de', // Language of the application
      dealerIds: [###dealerIds###], // Dealer IDs provided by Carmarket
  }
</script>
<script type="module" src="https://whitelabel.carmarket.ch/v1/carmarket.js"></script>
```

## Steps to embed on a page

1. Add the **HTML Element** `<div id="carmarket-frame"></div>`

2. Add the **Script Elements** to the page.

    1. Script element with the configuration settings. See chapter "Configuration" for a full list of available settings.
    2. Script element with the library `carmarket.js`

3. If Content Security Policy (**CSP**) is enabled, add the domain `*.carmarket.ch` to the CSP of the page (see https://developer.mozilla.org/en-US/docs/Web/HTTP/CSP for more info).

```text
Content-Security-Policy: frame-src *.carmarket.ch; script-src *.carmarket.ch
   ```

4. If a sticky header is present, make sure to set `scroll-margin-top` via CSS to the Carmarket-frame inner iframe (see https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-margin-top for more info)
```css
#carmarket-frame iframe {
    scroll-margin-top: 200px; /* choose height of page header if present */
}
   ```

5. Enhance the informations about Carmarket in the **Privacy Policy** of your website.
