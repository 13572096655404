export const name = 'SLA, Impressum'
export const order = 4;

# SLA

Carmarket assumes no liability for the availability of the marketplace at any time, as well as for other service disruptions due to causes beyond Carmarket's control, nor for the content posted.

## Impressum

Carmarket AG, Thurgauerstrasse 35, 8050 Zürich, Switzerland

044 495 27 40

[info@carmarket.ch](mailto:info@carmarket.ch)
