export const name = 'Examples'
export const order = 2;

# Examples

## Full Whitelabel Solution

![Example for Full Whitelabel Solution](/static/assets/whitelabel-docs/visual_example_whitelabel_v1.jpg)

### Snippet

The sample HTML page below embeds a CarmarketFrame in the language `de` for a dealer with two locations. The numbered comments in the HTML are explained in the list below the example.

```html
<html lang="de" class="notranslate" translate="no">
  <head>
    <meta charset="utf-8">
    <meta name="robots" content="notranslate" />
    <meta name="google" content="notranslate" />
    <title>Page Title ...</title>
  </head>
  <body>
    <header>...</header>
    <!-- 1. The CarmarketFrame will replace the following <div> element -->
    <div id="carmarket-frame"></div>
    <footer>...</footer>
    <!-- 2. Script to define the configuration settings -->
    <script>
      window.carmarketFrameConfiguration = {
          selector: '#carmarket-frame', // Selector of the element to replace
          type: 'whitelabel', // optional
          id: '###frameId###', // provided CarmarketFrame ID
          language: 'de', // language of the application
          dealerIds: [###dealerIds###], // dealer IDs (locations), provided by Carmarket
          brandIds: [], // optional, provided by Carmarket on request

          sidebarFilters: ['vehicleCondition', 'price', 'mileage'], // optional
          excludedFilters: ['interiorColor', 'exteriorColor'], // optional
          searchPlaceholder: 'z.B. Ford', // optional

          themePageBackground: '#ffffff',
          themePageForeground: '#1E1E1E',

          themePrimary: '#2A5CAA',
          themePrimaryHover: '#000000',
          themeOnPrimary: '#ffffff',

          themeSecondary: '#6E7681',
          themeSecondaryHover: '#000000',
          themeOnSecondary: '#ffffff',

          themeBoxBackground: '#F0F3F7',
          themeBorder: '#999999',
          themeMeta: '#999999',
      }
    </script>
    <!-- 3. Script to load the CarmarketFrame application -->
    <script type="module" src="https://whitelabel.carmarket.ch/v1/carmarket.js"></script>
  </body>
</html>
```

1. The `div` element in this section identifies the location on the page where the CarmarketFrame API will place the embedding iframe.
2. All the configuration settings are defined in a `script` element before the application is loaded. The configuration settings are stored in a global Javascript object (JSON) named `carmarketFrameConfiguration`. See configuration setting in detail in the next chapter.
3. The CarmarketFrame application is loaded with a single line of Javascript as a module from source `https://whitelabel.carmarket.ch/v1/carmarket.js`

## Vehicle Row

![Example for Vehicle Row](/static/assets/whitelabel-docs/visual_example_vehicle-row_v1.jpg)

### Snippet

The sample HTML page below embeds a CarmarketFrame in the language `de` for a dealer with two locations. The numbered comments in the HTML are explained in the list below the example.

```html
<html lang="de" class="notranslate" translate="no">
  <head>
    <meta charset="utf-8">
    <meta name="robots" content="notranslate" />
    <meta name="google" content="notranslate" />
    <title>Page Title ...</title>
  </head>
  <body>
    <header>...</header>
    <!-- 1. The CarmarketFrame will replace the following <div> element -->
    <div id="carmarket-frame"></div>
    <footer>...</footer>
    <!-- 2. Script to define the configuration settings -->
    <script>
      window.carmarketFrameConfiguration = {
          selector: '#carmarket-frame', // Selector of the element to replace
          type: 'vehicle-row', // optional
          id: '###frameId###', // provided CarmarketFrame ID
          language: 'de', // language of the application
          dealerId: ###dealerIds###, // dealer ID (single location), provided by Carmarket
          searchTermString: 'otherFilters=4&fuelType=g%7C1' // optional filters on vehicles
      }
    </script>
    <!-- 3. Script to load the CarmarketFrame application -->
    <script type="module" src="https://whitelabel.carmarket.ch/v1/carmarket.js"></script>
  </body>
</html>
```

1. The `div` element in this section identifies the location on the page where the CarmarketFrame API will place the embedding iframe.
2. All the configuration settings are defined in a `script` element before the application is loaded. The configuration settings are stored in a global Javascript object (JSON) named `carmarketFrameConfiguration`. See configuration setting in detail in the next chapter.
    1. For a vehicle row the type is `vehicle-row`
    2. The `searchTermString` in this example filter for vehicles with a promotion and fuel type petrol.
3. The CarmarketFrame application is loaded with a single line of Javascript as a module from source `https://whitelabel.carmarket.ch/v1/carmarket.js`
