import React, { ReactNode, StrictMode } from "react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { Params } from "../router/constants/Params";
import { LinkContextRootProvider } from "../modules/common/components/ContextLink";
import DocRenderer from "./DocRenderer";

import "github-markdown-css/github-markdown-light.css";
import "./styles/styles.css";
import "highlight.js/styles/docco.css";

const devMode = import.meta.env.DEV;

type Props = {
  children: ReactNode;
};

const EnvRouter = ({ children }: Props) => {
  return devMode ? <HashRouter>{children}</HashRouter> : <BrowserRouter>{children}</BrowserRouter>;
};

export default () => {
  return (
    <StrictMode>
      <LinkContextRootProvider>
        <EnvRouter>
          <Routes>
            <Route element={<DocRenderer />} path="/" />
            <Route element={<DocRenderer />} path={`/:${Params.pageId}`} />
          </Routes>
        </EnvRouter>
      </LinkContextRootProvider>
    </StrictMode>
  );
};
